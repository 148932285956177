<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                 Timetable printing
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\   Timetable printing

                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col v-if="currentUser.access_type == 'federal'" class="d-flex" cols="12" sm="6" md="4">
                    <v-select
                        label="SCORE"
                        v-model="search.score_id"
                        :items="scores"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        @change="handleScoreChange"
                        @input="search.score_id = $event !== null ? $event : ''"
                        clearable
                        v-on:keyup.enter="getPendingTimeTable"
                    ></v-select>
                  </v-col>
                    <v-col
                        cols="12" sm="6" :md="currentUser.access_type != 'score' ? 4 : 6"
                    >
                        <v-menu
                            ref="menuDate"
                            v-model="menuStartDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="search.start_date"
                                    label="View from date"
                                    readonly
                                    outlined
                                    dense
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="search.start_date"
                                outlined
                                dense
                                no-title
                                @input="menuStartDate = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col
                        cols="12" sm="6" :md="currentUser.access_type != 'score' ? 4 : 6"
                    >
                        <v-menu
                            ref="menuDate"
                            v-model="menuEndDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="search.end_date"
                                    label="View up to date"
                                    readonly
                                    outlined
                                    dense
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="search.end_date"
                                outlined
                                dense
                                no-title
                                :min="search.start_date"
                                @input="menuEndDate = false"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>


                    
                    <v-col class="d-flex" cols="12" sm="4" >
                        <v-select 
                            label="Select Location" 
                            v-model="search.location_id" 
                            :items="locations"
                            item-text="name"
                            item-value="id"
                            outlined 
                            @change="handleLocationChange"
                            :loading="isLocationLoading"
                            dense
                            @input="search.location_id = $event !== null ? $event : ''" 
                            clearable
                            v-on:keyup.enter="getPendingTimeTable"
                        ></v-select>
                    </v-col>

                    <v-col  class="d-flex" cols="12" sm="6" md="4">
                        <v-select 
                            label="Select Venues" 
                            v-model="search.venue_id" 
                            :items="venues"
                            item-text="name"
                            item-value="id"
                            outlined 
                            dense
                            :loading="isVenueLoading"
                            @input="search.venue_id = $event !== null ? $event : ''" 
                            clearable
                            v-on:keyup.enter="getPendingTimeTable"
                        ></v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="6" md="4">
                    <v-autocomplete 
                        label="Exam session" 
                        v-model="search.exam_session_id" 
                        outlined 
                        dense
                        :items="examSessions"
                        item-text="name"
                        item-value="id"
                        :loading="isExamSessionLoading"
                        @input="search.exam_session_id = $event !== null ? $event : ''" 
                        clearable
                    ></v-autocomplete>
                    </v-col>


                    <v-col cols="12" md="12" class="text-right">
                    <v-btn 
                        @click="searchTimeTable()" 
                        class="btn btn-primary btn-search"
                        style="color: #fff"
                        :loading="loading"
                    >
                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                        Search
                    </v-btn>
                    </v-col>
                </div>
            </div>

            <div class="text-right">
              <v-btn
                  x-large
                  text
                  class="ml-2 mt-2"
                  :loading="isAllTimetablesLoading"
                  @click="prepareTimetable('all')"
              >
                Prepare all
              </v-btn>
                <v-btn
                    x-large
                    dark
                    :loading="isSelectedTimetablesLoading"
                    @click="prepareTimetable('selected')"
                >
                    Prepare selected
                </v-btn>


            </div>
            
            <div class="table-responsive mt-2">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>

                <table class="table" v-if="!loading">
                    <thead>
                        <tr class="text-left">
                        <th class="px-3">
                            <v-checkbox
                                @click="checkAllCandidate"
                                v-model="checkAllCandidateFlag"
                            ></v-checkbox>
                        </th>
                        <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Name </th>
                        <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Date </th>
                        <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Time </th>
                        <th class="px-3">Location </th>
                        <th class="px-3" style="max-width: 400px !important; white-space: pre-wrap;">Session </th>
                        <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Venue </th>
                        <th class="px-3" style="max-width: 300px !important; white-space: pre-wrap;">Room </th>
                        <th class="px-3">Supervisors </th>
                        <th class="px-3">Examiners </th>
                        <th class="px-3">Status </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template>
                            <tr v-for="(item, index) in pendingTimeTables" :key="index">
                                <td class="px-2">
                                    <v-checkbox
                                        v-model="exam_day_ids"
                                        :value="item.id"
                                    ></v-checkbox>
                                </td>
                                <td style="max-width: 270px !important; white-space: pre-wrap;" class="px-2">
                                    <span class="font-size-lg">
                                    {{ item.name }}
                                    </span>
                                </td>
                                <td style="max-width: 270px !important; white-space: pre-wrap;">
                                    <span class="font-size-lg">{{item.exam_date_formatted}}</span>
                                </td>
                                <td style="max-width: 270px !important; white-space: pre-wrap;">
                                    <span class="font-size-lg">{{item.exam_start_time}} - {{item.exam_end_time}}</span>
                                </td>
                                <td>
                                    <span class="font-size-lg">{{item.location}}</span>
                                </td>
                                <td style="max-width: 400px !important; white-space: pre-wrap;"> 
                                    <div class="font-size-lg">{{item.exam_session_name ? item.exam_session_name : '-' }}</div>
                                    <div class="font-size-lg">{{item.exam_session_start_date +'-'+ item.exam_session_end_date }}</div>
                                </td>
                                <td style="max-width: 270px !important; white-space: pre-wrap;">
                                    <span class="font-size-lg">{{item.venue}}</span>
                                </td>
                                <td style="max-width: 300px !important; white-space: pre-wrap;">
                                    <span class="font-size-lg">{{item.room}}</span>
                                </td>
                                <td>
                                    <ul>
                                        <li 
                                            class="font-size-lg" 
                                            v-for="(supervisor, idx) in item.supervisor_list_full_name"
                                            :key="idx"
                                        >
                                            {{supervisor.first_name}} {{supervisor.last_name}}
                                        </li>
                                        <div class="font-size-lg">
                                            <div> Notification:</div>  
                                            <span v-if="!item.is_supervisor_notified" class="badge badge-danger">Pending</span>
                                            <span v-if="item.is_supervisor_notified" class="badge badge-success">Notified</span>
                                        </div>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li 
                                            class="font-size-lg" 
                                            v-for="(examiner, idx) in item.examiner_list_full_name"
                                            :key="idx"
                                        >
                                            {{examiner.first_name}} {{examiner.last_name}}
                                        </li>
                                        <div class="font-size-lg">
                                            <div class="mt-3"> Notification:</div>  
                                            <span v-if="!item.is_examiner_notified" class="badge badge-danger">Pending</span>
                                            <span v-if="item.is_examiner_notified" class="badge badge-success">Notified</span>
                                        </div>
                                    </ul>
                                </td>
                                <td>
                                    <div class="font-size-lg">
                                        <span class="badge" :class="item.is_confirmed ? 'badge-success' : 'badge-danger'">
                                            {{item.is_confirmed ? 'Day Confirmed' : 'Not Confirmed' }}  
                                        </span>
                                        <div class="my-2" v-if="item.candidates_count > 0 && !item.is_confirmed">
                                            <span class="badge badge-warning">Saved for later</span>
                                        </div>
                                        <!-- {!! $pendingSession->empty_slots==1 ? $session->empty_slots_minutes : '' !!}  //ask this -->

                                        <div class="my-2" v-html="item.exam_day_status"></div>
                                        <div>
                                            <b> Enroller:</b>
                                            <span class="badge" :class="item.is_account_holder_notified ? 'badge-success' : 'badge-danger'">
                                            {{item.is_account_holder_notified ? 'Notified' : 'Pending Notification' }}  
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="pendingTimeTables.length == 0">
                                <td colspan="9" class="text-center">
                                    No items found
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

              <b-pagination
                  v-if="pendingTimeTables.length > 0"
                  class="pull-right mt-7"
                  @input="getPendingTimeTable"
                  v-model="page"
                  :total-rows="total"
                  :disabled="loading"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
            
          </div>
        </div>
      </div>
      
    </div>
  </v-app>
</template>

<script>
import PendingPrintService from "@/services/pending-print/PendingPrintService";
import LocationService from "@/services/practical-exam/location/LocationService";
import VenueService from "@/services/practical-exam/venue/VenueService";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
import ScoreService from "@/services/score/score/ScoreService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import {required} from "vuelidate/lib/validators";

const pendingPrint = new PendingPrintService();
const examDay = new ExamDayService();
const score = new ScoreService();
const venue = new VenueService();
const location = new LocationService();
const examSession = new ExamSession();

export default{
    validations:{
        exam_day_ids:{required}
    },
    data(){
        return {
            total: null,
            perPage: null,
            page: null,
            loading: false,
            pendingTimeTables: '',
            search: {
                start_date: '',
                score_id: '',
                location_id: '',
                venue_id: '',
                end_date: '',
                exam_session_id: '',
            },
            scores:[],
            venues: [],
            locations: [],
            exam_day_ids:[],
            isSelectedTimetablesLoading: false,
            isAllTimetablesLoading: false,
            currentUser:{},
            checkAllCandidateFlag: false,
            menuStartDate: false,
            menuEndDate: false,
            isVenueLoading: false,
            isLocationLoading: false,
            isExamSessionLoading: false,
            isExamDayLoading: false,
            examSessions: [],
        }
    },
    methods:{
        checkAllCandidate(){
            if(this.checkAllCandidateFlag){
                this.pendingTimeTables.forEach(item => {
                    this.exam_day_ids.push(item.id);
                });
            }
            else{
                this.exam_day_ids = [];
            }
        },
        getPendingTimeTable(){
            this.loading = true;
            examDay
            .getPendingTimetableLists(this.search, this.page)
            .then((response) => {
                this.pendingTimeTables = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
            })
            .finally(() => {

            });
        },
        searchTimeTable(){
            this.total = null;
            this.perPage = null;
            this.page = null; 
            this.getPendingTimeTable();
        },
        prepareTimetable(type){
            let data = {
                exam_day_ids: this.exam_day_ids,
                start_date: this.search.start_date,
                score_id: this.search.score_id,
                location_id: this.search.location_id,
                venue_id: this.search.venue_id,
                end_date: this.search.end_date,
                exam_session_id: this.search.exam_session_id,

            };

            if(type == 'selected'){
                this.$v.$touch()
                if (this.$v.$error) {
                    this.$snotify.error('Minimum of one enrolment is required to continue');
                } else {
                    this.isSelectedTimetablesLoading = true;
                    this.sendTimetableData(type, data);
                }
            }

            if(type == 'all'){
                this.isAllTimetablesLoading = true;
                this.sendTimetableData(type, data);
            }
        },
        sendTimetableData(type, data){
            pendingPrint
            .prepareTimetables(type, data)
            .then((response) => {
                this.$snotify.success(response.data.message);
                this.isSelectedTimetablesLoading = false;
                this.isAllTimetablesLoading = false;
                this.exam_day_ids = [];
                this.checkAllCandidateFlag = false;
                this.getPendingTimeTable();
            })
            .catch((err) => {
                this.$snotify.error('Oops looks like something went wrong.');
                this.isSelectedTimetablesLoading = false;
                this.isAllTimetablesLoading = false;
            })
            .finally(() => {

            });
        },
        getCurrentUser() {
            this.currentUser =  this.$store.getters.currentUser;
            if(this.currentUser.access_type == 'score'){
                this.getLocations(this.currentUser.score_id);
            }
            if(this.currentUser.access_type == 'federal'){
                this.getScores();
            }
        },
        getLocations(score_id){
            location
            .getByScore(score_id)
            .then((response) => {
                this.locations = response.data;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        getScores(){
            score
            .all()
            .then(res => {
                this.scores = res.data.scores;
            })
            .catch((err) => {

            });
        },
        getVenues(){
            venue
            .getByLocation(this.search.location_id)
            .then(res => {
                this.venues = res.data;
            })
            .catch((err) => {

            });
        },
        handleScoreChange(){
            this.isLocationLoading = true;
            
            if(this.search.score_id == ''){
                this.search.location_id = '';
                this.locations = [];
                this.search.venue_id = '';
                this.venues = [];
                this.search.exam_session_id = '';
                this.examSessions = [];
            }
            
            if(this.search.score_id != ''){
                this.getLocations(this.search.score_id);
                this.getExamSessions(this.search.score_id);
            }
            this.isLocationLoading = false;
        },

        handleLocationChange(){
            this.isVenueLoading = true;
            
            this.search.exam_session_id = '';
            this.examSessions = [];

            if(this.search.location_id == ''){
                this.venues = [];
                this.search.venue_id = '';
            }
            
            if(this.search.location_id != ''){
                this.getVenues(this.search.location_id);
                this.getExamSessions(this.search.score_id ? this.search.score_id : this.currentUser.score_id);
            }
            this.isVenueLoading = false;
        },
        
        getExamSessions(score_id){
            this.isExamSessionLoading = true;
            let data = { location_id: this.search.location_id};
            examSession
            .getByScore(score_id, data)
            .then((response) => {
                this.examSessions = response.data;
                this.search.exam_day_id = '';
                this.examDays = [];
                this.isExamSessionLoading = false;
            })
            .catch((err) => {
                this.isExamSessionLoading = false;
            })
            .finally(() => {

            });
        },
    },
    mounted(){
        this.getPendingTimeTable();
        this.getCurrentUser();
    }
}
</script>
